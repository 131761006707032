import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import jiva_logo from '../../assets/images/projects/service/tech/jiva-logo.png'
import pic04 from '../../assets/images/projects/25.png'
import resume from '../../assets/CV/acrole-casestudy.pdf'
import { useRef } from 'react'
import ACvoice from '../../assets/videos/ACvoice.mp4'







const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ServiceDesignTecne = props => {

  const outcomeDiv = useRef(null);

  const scrolLWithUseRef = () => outcomeDiv.current?.scrollIntoView({ block: "start", behavior: "smooth" });

  return (
  <Layout>
    <Helmet>
      <title>Service Design - Redesign internal team role</title>
      <meta name="Service Design - Redesign internal team role" content="Service Design" />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.tecnecover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <p className="maisontags">AI-enabled, Research, UX & Business Strategy, Service Design, User Experience, Digital UX/UI</p>
            
            <h1>
              From offline to AI-enabled: Transforming Field-Ops to deliver better services to users.
            </h1>
            <h3>
            We redefined the role of Activation Coordinators (ACs) in our field-ops team 
            by implementing new service processes and AI-powered digital tools, enhancing
             workflow efficiency and enabling data-driven decisions to encourage user transactions.
            </h3>
            <h3>
            To enhance effectiveness, we upskilled the ACs and introduced support staff to handle non-essential tasks, allowing ACs to focus on their core responsibilities.
            {/* We transformed the role of Activation Coordinators (ACs) by implementing 
            new service processes and AI-enabled digital tools to boost workflow efficiency and cut coordination 
            time. We upskilled the ACs and added support staff to handle non-essential
             tasks, allowing them to focus on core responsibilities.  */}
             </h3>
             <h3>
             These efforts fostered productive relationships between ACs and our users, improved the quality of service, and reduced the overall cost of service delivery.
             {/* Overall, these efforts have resulted in an improvement in the quality 
             of service recevived by our users while reducing the cost of serving them. */}
            </h3>

            {/* <h3>
            Prototyping and piloting multiple initiatives to re-design 
            the role of a field-ops employee (Activation Coordinator, AC)
            to reduce the cost to serve users, improve retention and
            quality of service delivery. 
            </h3> */}

          </header>


          <section className="header-post">
            <div className="headerpost">
            {/* <div className="headerpost-left">
                
            <p>
                A summarised version of the project is presented here. 
                <br /> For a much more in-depth information, feel free to download the detailed case study document for this project.
                <br />
                <div className="resume-download">
                <a href={resume} target="_blank" rel="noopener noreferrer" className="resume-download-link" download>
                📘 Download case study</a>
                </div> 
                </p>
              </div> */}

              {/* <div className="headerpost-right">
                <div className="client-image">
                  <img className="span-4" src={jiva_logo} alt="things logo" />
                </div>
                <br />
                <p>
                I worked on this project while working at Jiva Ag, an AgriTtech product company.
                <br />
                <br />
                <a href="/about-jiva"><u>know more about Jiva</u></a>
                </p>
              </div>             */}

            </div>
          </section>

          <div className="process">           
          <div className="process-left">
                <h4>
                Context of the project
                </h4>
                <span className="credits-text">
                Activation Coordinators (ACs) are Jiva's field-ops employees who provide on-ground support to our users, Micro Collectors (MCs), enabling regular engagement and transactions.
                </span>
                <br />
                <span className="credits-text">
                While facilitating a Service Blueprinting workshop we found that ACs had taken on additional responsibilities, creating bottlenecks and negatively impacting the business.
                </span>
                <br />
                <span className="credits-text">
                As a result, 'redesigning the role of the ACs' became a key focus for the organization in H1 2024.
                </span>
                <br />
                <span className="credits-text">
                <a href="/about-jiva"><u>know more about Jiva's stakeholder ecosystem</u></a>
                </span>
                </div>
          <div className="process-right">
              <h4>
              Industry              
              </h4>
              <span className="credits-text">
              AgriTech
              </span>
              <br />
              <h4>
              My role
              </h4>
              <span className="credits-text">
              🔍 The Researcher, 🎯 the UX Strategist, ✍️ the Service Experience Designer, 🦮 the Facilitator, 🎬 the Orchestrator and 📱the UX/UI Designer.
              <br />
              <br />
              I collaborated with Users, Leadership team, Field-ops teams, Operations leads, Business Strategist, Front & Back-end Developers, Product Managers, Researchers and Service & Product Designer.
              </span>
              <br />
              </div>
            </div>


          <section className="challenge-section">

            {/* <span className="project-step-number">01</span>
            <h2>the challenge</h2> */}
            {/* <div className="challenge">
              <div className="challenge-desc">
                <h4>
                The Activation Coordinator (AC)
                </h4>

                <p>ACs are field-ops employees of Jiva who provide on-ground support to our users, Micro Collectors (MCs). </p>

                <p className="half-textlarge">
                ACs are enablers in getting Jiva's users to engage and transact on a regular basis.
                </p>

                <p>
                Mardiana, an AC, graduated with a degree in Agriculture. 
                She used to be a Demo Plot Controller back in 2021 and 
                now she is an AC for Bontoramba, sub-district.
                </p>
                <p>
                She is passionate about making better lives for 
                farmers as she understands the same struggles 
                her family has been through in agriculture.
                </p>
                
              </div>
            </div> */}

            <section className="fullspan">
            <div className="image">
              <Img fluid={props.data.tecnechallenge3.childImageSharp.fluid} />
            </div>

            <div className="image">
              <Img fluid={props.data.tecnechallenge1.childImageSharp.fluid} />
            </div>
            </section>

            <div className="project-outcome">
                <span className="project-outcome-link" onClick={scrolLWithUseRef}>
              see project outcomes >>
                </span>
              </div>
              

            </section>


            <section className="process-section">

              {/* <p className="full-textlarge">
              What the Activation Coordinator’s (ACs) role was envisioned to be... and what it became organically...
              </p>  

              <div className="image">
              <Img fluid={props.data.tecnechallenge2.childImageSharp.fluid} />
              </div> */}

            <div className="changebg">
            <div className="process">           
            <div className="process-left">
            <p className="half-textlarge">
            ACs had unintendedly taken on multiple roles.
            </p>
            </div>

              <div className="process-right">
              <h4>Impact on ACs</h4>  
              <p>
              ACs struggle to balance meeting their KPIs and supporting all MCs, leading to ineffectiveness in their roles.
              {/* ACs are ineffective at their job as they have to repeatedly balance between meeting their KPIs and supporting all their MCs. */}
              </p> 
              <h4>Impact on users (Micro collectors - MCs)</h4>  
              <p>
              MCs with potential miss crucial support, weakening their trust in Jiva.
              {/* MCs with great potential miss out on being supported at crucial junctures, this weakens their trust in Jiva.    */}
              </p> 
              <h4>Impact on Jiva (business)</h4>  
              <p>
              ACs can only support a limited number of MCs, hindering scalability due to high costs in serving users.
              {/* ACs can only support a handful of MCs to push them to be active making it difficult for Jiva to scale and serve users in a cost effective manner. */}
              </p>       
              </div>
            </div> 

            <div className="image">
              <Img fluid={props.data.tecneprocess3.childImageSharp.fluid} />
            </div>

            </div>

            

            </section>



          <section className="process-section">

            {/* <span className="project-step-number">02</span>
            <h2>the approach</h2>

            <br />
            <br /> */}

            {/* <div className="process-image">
              <Img fluid={props.data.tecne_process.childImageSharp.fluid} />
            </div> */}

            {/* <div className="process">           
            <div className="process-right">
            <p className="half-textlarge">
            We began by framing the problem space & desired UX 
            & Business outcomes together with the leadership.
            </p>
            </div>

            
            </div>           */}

            <section className="fullspan">
            <div className="image">
              <Img fluid={props.data.tecneprocess24.childImageSharp.fluid} />
            </div>
            </section>
            
            {/* <div className="process">           
            <div className="process-left">
            <h4>We sought to understand the activities, needs, and challenges of the ACs.</h4>
              </div>
              <div className="process-right">
              <p>
              A research plan was put together outlining the 
              objectives, methodologies, line of inquiry, participant 
              criteria & timelines.
              </p>     
              </div>
            </div> */}

            {/* <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneprocess4.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess18.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess19.childImageSharp.fluid} />
            </Slider> */}
            
            {/* <div className="image">
              <Img fluid={props.data.tecneprocess4.childImageSharp.fluid} />
            </div> */}


            <div className="process">           
            <div className="process-left">
            <h4>We sought to understand the activities, needs, and challenges of the ACs.</h4>
            {/* <p className="half-textlarge">
              We understood AC’s context, their day-to-day tasks 
              and how they were managing their network of MCs.
              </p> */}
              </div>
              <div className="process-right">
              {/* <h4>We sought to understand the activities, needs, and challenges of the ACs.</h4> */}
              <p>
              This included their daily context, task execution, decision-making processes in managing their network of MCs, and how they prioritize which MCs to serve and visit in person.
              </p>     
              </div>
            </div>




            {/* <p className="full-textlarge">
            We started with understanding the AC’s context; their 
            day-to-day tasks & challenges. We conducted on-ground 
            discussions with 6 ACs.
            </p> */}

            <section className="fullspan">
            <div className="image">
              <Img fluid={props.data.tecneprocess5.childImageSharp.fluid} />
            </div>
            </section>

            <p className="full-textlarge">
            All we heard was, "Everything is good, sir! No problems!" 
            <br /><br />However, we knew that wasn't the case; the numbers told a different story.
              </p>

              <section className="fullspan">
              <div className="image">
              <Img fluid={props.data.tecneprocess7.childImageSharp.fluid} />
            </div>
            </section>

            <div className="process">           
            <div className="process-left">
              <p>
              During our research, we discovered that culturally, they hesitated to complain or share negative experiences. They also found it challenging to articulate their problems.
              </p>
              </div>
              <div className="process-right">
              <p>
              To address this, we adjusted our approach. We enlisted a local to accompany them and modified our questioning to encourage storytelling, helping them better articulate their challenges.
              </p>     
              </div>
            </div>



              <section className="fullspan">
            <div className="image">
              <Img fluid={props.data.tecneprocess25.childImageSharp.fluid} />
            </div>
            </section>



            {/* <div className="process">           
            <div className="process-left">
            <h4>We dug deeper to understand how ACs are currently managing their network of MCs. We wanted to learn about the following things</h4>
              </div>
              <div className="process-right">
              <p>
              Time spent visiting MCs.<br />
              Time spent remotely interacting MCs.<br />
              Reasons for them initiating visits MCs.<br />
              Reasons for MCs requesting AC visits.<br />  
              Their actions and role while visiting MCs.<br />
              Challenges to doing certain tasks remotely.
              </p>     
              </div>
            </div> */}

            {/* <div className="image">
              <Img fluid={props.data.tecneprocess6.childImageSharp.fluid} />
            </div> */}


            <div className="process">           
            <div className="process-left">
            <p className="half-textlarge">
            Social hierarchy instills in ACs a sense of responsibility for the entire journey of MCs in Jiva.
              </p>
              <p>
              ACs, often younger college graduates, manage relationships with older MCs who expect visits as a sign of respect. 
              <br />
              <br />
              This blurred the lines between personal and professional interactions as MCs become over dependent on them for everything.
              </p>
              </div>
              <div className="process-right">
              <p className="half-textlarge">
              Jiva lacked visibility into on-ground activities and the quality of service provided by ACs to MCs.
              </p>
              <p>
              ACs relied on memory, and used manual & offline communication channels to get info,  manage MCs and their activities & visits.
              </p>
              <p>
              High-friction for new MCs to get started with Jiva, requiring significant effort from ACs and leading to prolonged dependency on them.
              </p>    
              </div>

            </div>

            {/* <div className="bluebg">
            <p className="full-textlargebluebg">
            How might we enable ACs to efficiently manage and engage a larger MC network, increase transaction rates, and ensure Jiva serves its users in a cost-effective manner?
            </p>
            </div> */}

            <div className="changebg">

            <div className="process"> 
            <div className="process-left"> 
            <p>
            We also spoke to the MCs that the ACs served to understand their traits, motivations and needs. 
            </p>
            </div>
            <div className="process-right">
            <p>
            This allowed us to understand what was expected of the ACs when it came to managing the different MCs profiles.
            </p>
            </div>
            </div>

            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneprocess42.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess36.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess37.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess38.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess39.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess40.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess41.childImageSharp.fluid} />
            </Slider>

            </div>

            <p>
            We mapped the current end-to-end experience of the AC to identify pains, inefficiency points & opportunities for redesign.
            </p>

            

            {/* <p className="full-textlarge">
              What the Activation Coordinator’s (ACs) role was envisioned to be... and what it became organically...
              </p>   */}

              {/* <div className="image">
              <Img fluid={props.data.tecnechallenge2.childImageSharp.fluid} />
              </div> */}


            {/* <div className="process">           
            <div className="process-left">
            <h4>Quantitative data analysis</h4>
            </div>
            <div className="process-right">
            <p>We also looked at quant data to understand how the 
            AC’s network of MCs are performing — activation rate, transactions, etc.
            </p>
            
            
            </div>
            </div> */}

            

            {/* <p className="full-textlarge">
            We realised that a considerable amount of AC’s capacity could 
            be freed up if low-volume MCs were to be 
            managed by another team.
            </p> */}

            
            {/* <div className="process">           
            <div className="process-left">
            <p className="half-textlarge">
            A considerable amount of AC’s capacity could 
            be freed up if low-volume MCs were to be 
            managed by another team.
            </p>
            </div>

            

            <div className="process-right">
            <p>
            70% volume contribution by 17% MC.<br />
            40% of AC’s MC network is low-volume.<br />
              Average AC span is 12 transacting MCs i.e. 1 big, 1 medium, 1 small and 9 tail-end MCs.<br />
              A considerable amount of AC’s capacity could be freed up if low-volume MCs were to be managed by a remote team instead.<br />
            </p>
            </div>
            </div> */}

            {/* <div className="process">           
            <div className="process-right">
            <p>
            We simulated the possible effort & time distribution 
            of ACs across their MC network (new MCs, mid vol MCs 
            and high vol MCs), in order to meet 2024 targets.
            </p>
            </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess8.childImageSharp.fluid} />
            </div> */}

            <section className="fullspan">
            <div className="image">
              <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
            </div>
            </section>

 


            {/* <p className="full-textlarge">
            Research synthesis resulted in mapping of the current
             experience of the AC to identify pains, inefficiency 
             points & opportunities for redesign.
            </p> */}

            

            

            {/* <div className="process">
            <div className="process-left">
            <p className="half-textlarge">
            This allowed us to establish a strategic approach & guiding principles for the role redesign
            </p>
            </div>
            <div className="process-right">
              <p>
              Capability — Upskilling ACs to be able to manage MCs effectively.
              </p> 
              <p>
              Incentives — Changing the KPIs and incentive structure of ACs in order to motivate their behaviour.   
              </p> 
              <p>
              Support people — Removing things off AC’s plate by surrounding them with other teams and people.
              </p> 
              <p>
              Tools — Touchpoints to equip ACs with so that they can work efficiently and effectively.
              </p> 
              <p>
              Processes — Introducing new service flows, SOPs and Jiva policies that ACs use as frameworks & guardrails.
              </p>       
              </div>
            </div> */}

            {/* <div className="image">
              <Img fluid={props.data.tecneprocess10.childImageSharp.fluid} />
            </div> */}

            {/* <div className="process">
            <div className="process-left">
            <p className="half-textlarge">
            1. Improvement in average MC volume
            </p>
            <p>
            Increase wallet share as ACs can improve Jiva volume share from MCs total business in their existing network.
            <br />
            <br />
            Increase in average throughput/transactions per user (MC).
            </p>
            </div>
            <div className="process-right">
            <p className="half-textlarge">
            2. Improvement in AC span to manage MCs
            </p>
            <p>
            Create better focus for ACs by adding support to take away low value responsibilities. 
            <br />
            <br />
            Increase capacity by making tasks redundant by product support and replacing in-person visits with remote interactions.
            <br />
            <br />
            Improve ACs effectiveness & productivity by enabling ACs with better tools, processes (SOPs), and their capabilities
            </p>
            </div>

            </div> */}


           

            {/* <div className="process">
            <div className="process-left">
            <div className="image">
              <Img fluid={props.data.tecneprocess11.childImageSharp.fluid} />
            </div>
            </div>

            <div className="process-right">
            <h4>Field AC capacity</h4>
                <ol>
                  <li>Transition field AC’s in-person activities to remote ways of interaction.</li>
                  <br />
                  <li>Remote AC team to allow Field AC focus to dedicate focus to mid & high-value MCs.</li>
                  <br />
                  <li>Transaction support team to assist Field AC with executing low-impact functional work such as verifying and editing mistakes in transaction data.</li>
                  <br />
                  <li>Recovery team to follow-up on escalation use-cases.</li>
                </ol>

                <h4>Quality of service provision to user (MC)</h4>
                <ol>
                  <li>SOPs & guideline to standardise on-ground processes and usage of tools.</li>
                  <br />
                  <li>Bringing visibility & monitoring to AC’s on-ground activities to enable data-informed follow-up actions.</li>
                  <br />
                  <li>Field & remote ACs to have access to knowledge team for providing better quality advice to MCs.</li>
                  <br />
                  <li>Enable field & remote ACs with accurate & real-time data to proactively support MCs.</li>
                  <br />
                  <li>Field AC to provide a lower barrier experience for new MCs to begin working with Jiva.</li>
                  <br />
                  <li>Identify high-value MCs and provide them premium services that eliminate their high-friction points.</li>
                </ol>
            </div>
            </div> */}



            <div className="changebg">
            <div className="image">
              <Img fluid={props.data.tecneprocess26.childImageSharp.fluid} />
            </div>

            <div className="bluebg">
            <p className="full-textlargebluebg">
            ACs should be holistically empowered with the tools, processes, support, 
            and capabilities needed to make their workflows efficient and their 
            interactions with MCs productive and effective.
            </p>

            <div className="image">
              <Img fluid={props.data.tecneprocess43.childImageSharp.fluid} />
            </div> 

            </div>

            <div className="process">           
            <div className="process-left">
              <p className="half-textlarge">
              Involving the different teams created significant alignment challenges.
              </p> 
              
            {/* <h4>Initial ideation and consolidation of initiatives</h4> */}
              </div>
              <div className="process-right">
              <p>
              The unstructured growth of the AC role was partly due to internal team silos within Jiva.
              </p>
                {/* <p>
                To address this, we involved Business Strategy, Operations, Engineering, Design, Product teams, 
                and ACs to ensure a viable, feasible, and desirable future experience.
                </p> */}
                <p>
                We made the process tangible and enjoyable by organising ideation and alignment workshops across various regions in Indonesia.
                </p>
                <p>
                Our goals were to bring together different perspective, encourage feedback, and foster ownership within the ACs to deliver the experience to MCs.
                </p>
              </div>
              </div>


              <div className="image">
              <Img fluid={props.data.tecneprocess12.childImageSharp.fluid} />
            </div> 

            <div className="process"> 
            <div className="process-left">
              <p className="half-textlarge">
              The incentive structure sparked heated debates.
              </p> 
              </div>          
              <div className="process-right">
                <p>
                We identified a critical gap in how we recruited ACs and defined their incentives.
                </p>
                <p>
                The current incentive structure caused ACs to prioritise MCs unfairly and unsustainably.
                </p>
                <p>
                To address this, we engaged HR teams, recognising the direct impact on AC behavior and the indirect impact on the user journey.
                </p>
                {/* <p>
                Our goals were to present our perspective, encourage feedback and discussion, and foster ownership within the ACs to deliver the experience to MCs.
                </p> */}
              </div>
             
            </div>


            </div>

            {/* <div className="process">           
            <div className="process-left">
            <br />
            <br />
            <br />

            <p>Present different scenarios which ACs find themselves in; and for each scenario:</p>
                <ol>
                  <li>ideate ways to make the visit productive, remote interaction, remove it from their plate with support from tool or people.</li>
                  <br />
                  <li>break ideas - identify risks, constraints, cons</li>
                  <br />
                  <li>fix ideas - go around and fix ideas by derisking them, overcoming constraints, turning cons to pros.</li>
                  
                </ol>             
            </div>
              <div className="process-right">
              <div className="image">
              <Img fluid={props.data.tecneprocess12.childImageSharp.fluid} />
            </div>    
              </div>
            </div> */}

            



            {/* <p className="full-textlarge">
            To envision how it will all come together, we envisioned a future state service experience for our users, ACs and other internal teams. 
            </p>


            <section className="fullspan">
            <div className="image">
              <Img fluid={props.data.tecneprocess14.childImageSharp.fluid} />
            </div> 
             </section>  */}

            

            {/* <div className="image">
              <Img fluid={props.data.tecneprocess14.childImageSharp.fluid} />
            </div>  */}


            <section className="fullspan">
            <div className="image">
              <Img fluid={props.data.tecneprocess27.childImageSharp.fluid} />
            </div> 
             </section> 

             <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneprocess13.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess20.childImageSharp.fluid} />
            </Slider>

            <div className="process"> 
            <div className="process-left">
              <p>
              Alongside the framework below, we assessed initiatives based on desirability for ACs & MCs, viability for business, and tech & ops feasibility.
              </p> 
              </div>          
              <div className="process-right">
                <p>
                Multiple prototypes were designed and tested with a select group of ACs.
                </p>
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess16.childImageSharp.fluid} />
            </div>

            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneprocess32.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess33.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess34.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess35.childImageSharp.fluid} />
            </Slider>

            {/* <div className="process">           
            <div className="process-left">
            <h4>
            Prototype 1
            </h4>
            </div>

            <div className="process-right">
            <p>
            Hypothesis: <br />
            write hypothesis here. write hypothesis here. write hypothesis here. write hypothesis here.
            </p>
            <p>
            What we wanted to learn: <br />
            write learning here. write learning here. write learning here. write learning here.
            </p>
            </div>
            </div>
            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneoutcome46.childImageSharp.fluid} />
              <Img fluid={props.data.tecneoutcome47.childImageSharp.fluid} />
          </Slider> */}




            <div className="changebg">
            <div className="image">
              <Img fluid={props.data.tecneprocess28.childImageSharp.fluid} />
            </div> 
             <div className="process"> 
            <div className="process-left">
              <p>
              ACs were pleasantly surprised by the idea of using AI tools to enhance efficiency and productivity in doing manual tasks. 
              </p> 
              <p>
              They saw AI as a potential strategic ally for generating insights and suggesting next steps.
              </p>
              </div>          
              <div className="process-right">
                <p>
                However, they found it difficult to trust AI recommendations, doubting a lack of consideration of on-ground nuances, their relationships with MCs, and personal expertise. 
                </p>
                <p>
                Additionally, they worried about job loss if AI handled tasks like analysis, decision-making, and strategy.
                </p>
              </div>
            </div>



            <div className="image">
              <Img fluid={props.data.tecneprocess31.childImageSharp.fluid} />
            </div>

            <div className="process"> 
            <div className="process-left">
              <p className="half-textlarge">
              We turned AC's fear into an opportunity.
              </p> 
              </div>          
              <div className="process-right">
                <p>
                We incorporated on-ground nuances and AC experience into our AI model to build trust. 
                </p>
                <p>
                This was achieved by enabling ACs to efficiently record MC interactions through text, voice, and image capture.
                </p>
                <p>
                We also developed new upskilling programs to familiarize ACs with technology and AI methodologies.
                </p>
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess30.childImageSharp.fluid} />
            </div>
            </div>

            
          

            {/* <p className="full-textlarge">
              We debated pros and cons of the different initiatives based on desirability for user, 
              viability for business and feasibility for execution.
            </p> */}


            {/* <p className="full-textlarge">
              Finally quant and qual feedback loops were set up to monitor and continuously refine and optimise the service
            </p>

            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneprocess21.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess22.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess23.childImageSharp.fluid} />
            </Slider> */}

            
            <section className="fullspan">
            <div className="image">
              <Img fluid={props.data.tecneprocess29.childImageSharp.fluid} />
            </div> 
            </section>

            

            <section className="fullspan">
            <div className="image">
              <Img fluid={props.data.tecneprocess10.childImageSharp.fluid} />
            </div> 
            </section>  


            
            <div className="process">           
            <div className="process-left">
            <p className="half-textlarge">
            We became the glue 
              to bring the different work streams together.
              </p>
            </div>

            <div className="process-right">
              {/* <p>
              Facilitating collaboration with departments across the organisation to implement journey improvements.
              </p> */}
              <p>
              Team owners for different phases of the AC's journey were assigned.</p>
              <p>
              To ensure seamless implementation, we set up weekly/monthly cadences, 
              critique & feedback sessions, and energy-check routines.
              </p>
            </div>
            </div>


            <section className="fullspan">
            <div className="image">
          <Img fluid={props.data.tecneoutcome50.childImageSharp.fluid} />
          </div>  
          </section>



            {/* <div className="process">           
            <div className="process-left">
            <h4>Prirotise</h4>
              </div>
              <div className="process-right">
              <p>All initiatives & ideas were presented to key 
                stakeholders for alignment and prioritisation based on confidence level.  
              </p>
              <p>Ideas and initiatives that would require prototyping & piloting were then structured in "proto cards" as shown below</p>        
              </div>
            </div> 


            <div className="process">           
            <div className="process-left">
            <div className="image">
            <Img fluid={props.data.tecneprocess16.childImageSharp.fluid} />
            </div>  
            </div> 
            <div className="process-right">
            <Img fluid={props.data.tecneprocess17.childImageSharp.fluid} />
            </div>
            </div> */}
   

          </section>



          <section id="outcome-section" ref={outcomeDiv} className="outcome-section">
            <div className="outcome-text-wrapper">

            <section className="fullspan">
              <div className="image">
                <Img fluid={props.data.tecneoutcome66.childImageSharp.fluid} />
              </div>
              </section>

              {/* <span className="project-step-number">03</span>
              <h2>the outcome</h2> */}

              <h3>
              We streamlined the workflow for Activation Coordinators (ACs) with 
              advanced digital and AI tools, including a mobile app, and improved
               both online and offline processes.
              <br />
              <br />
              The AI voice bot reduced on-ground task time and enabled comprehensive recording of interactions with MCs.
              </h3>

              <div className="outcome">
                <div className="outcome-left">
                <h3>
                Enhanced on-ground visibility allowed Jiva to generate actionable insights through AI, guiding ACs in their MC interactions.    
                </h3>
                </div>
                <div className="outcome-right">
                <h3>
                This empowered ACs to make data-driven decisions, prioritize effectively, foster productive relationships, and drive transactions to meet incentive targets.
                </h3>
                </div>
              </div>

              <div className="image">
                <Img fluid={props.data.tecneoutcome67.childImageSharp.fluid} />
              </div>

              <h3>
              Real-time access to MC transactional and operational data eliminated the need for offline coordination, boosting AC efficiency. While, delegating low-value tasks allowed ACs to focus on high-value MCs, optimizing their efforts.
              </h3>

              <div className="outcome">
                <div className="outcome-left">
                <h3>
                A new AC-supported onboarding experience increased activation rates and reduced credit risk for Jiva.   
                </h3>
                </div>
                <div className="outcome-right">
                <h3>
                Customized upskilling programs made ACs knowledgeable and reliable, enhancing engagement with MCs. 
                </h3>
                </div>
              </div>

              <div className="image">
                <Img fluid={props.data.tecneoutcome68.childImageSharp.fluid} />
              </div>


              <div className="image">
                <Img fluid={props.data.tecneoutcome64.childImageSharp.fluid} />
              </div>

              <h3>
              ACs now provide quick, personalized services, improving overall service quality 
              and solidifying their value as on-ground assets. 
              <br />
              <br />
              These improvements resulted in increased wallet share from MCs, 
              higher average transactions per MC, and reduced service costs, 
              enabling Jiva to scale operations in a cost effective way.
              </h3>
              
            </div>
          </section>




          {/* <section className="process-section">
          <div className="process">
            <div className="process-left">
            <p className="half-textlarge">
            From having no on-ground visibility to getting a comprehensive view of AC-MC interactions.
            </p>
            </div>
            <div className="process-right">
            <h4>We ran 3 prototypes to test a hypothesis:</h4>  
            <p>
            Voice provides AC a low-effort and an efficient way to capture rich  details about their interaction with their MCs. 
            <br />
            <br />
            By making it simple and easy for AC’s to record details they are more likely to comply to capturing comprehensive on-ground details and nuances.
            <br />
            <br />
            AC-captured on-ground details can be combined with MC’s transaction & operational data to generate insights and suggestions for next actions for ACs.
            </p>
            </div>
          </div>

          <section className="fullspan">
        <div className="image">
          <Img fluid={props.data.tecneoutcome45.childImageSharp.fluid} />
        </div>
      </section>

          

          
          <br />
          <br />

          <div className="process">
          <div className="process-left">
          <h4>
          Prototype 1 - ACs recording visit details by voice note in Indonesian
          </h4>
          <p>
          Two ACs recorded details of their visit with every MC they met for 2 weeks via WhatsApp voice notes.
          </p>
          </div>
          </div>

          <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneoutcome46.childImageSharp.fluid} />
              <Img fluid={props.data.tecneoutcome47.childImageSharp.fluid} />
          </Slider>

          <br />
          <br />

          <div className="process">
          <div className="process-left">
          <h4>
          Prototype 2 - ACs conversing with GPT voice bot in Indonesian to capture details of their interaction with their MCs.
          </h4>
          <p>
          We created a custom chatGPT and Copilot GPT voice bot that would dynamically prompt the AC to 
          capture specific and comprehensive details.
          <br />
          <br />
          The custom GPT was given context about Jiva, ACs, and dynamic prompts and 
          follow-up questions it needs to ask the AC depending on the conversation.
          </p>
          </div>
          </div>

          <div className="image">
          <Img fluid={props.data.tecneoutcome48.childImageSharp.fluid} />
          </div> 

          <div className="video">
                <video controls>
                  <source src={ACvoice} type="video/mp4" />
                </video>
          </div>

          <br />
          <br />

          <div className="process">
          <div className="process-left">
          <h4>
          Prototype 3 - Create a custom GPT to generate summaries, insights & follow-up actions from voice recordings in Bahasa
          </h4>
          <p>
          We created a custom chatGPT and gave it context about Jiva, the role of AC & MC, and some business rules, etc.
          <br />
          <br />
          We inputted this GPT with Bahasa voice notes from prototype 1 and 2 and asked it to generate summaries, insights & next step recommendations.
          </p>
          </div>
          </div>

          <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneoutcome49.childImageSharp.fluid} />
              <Img fluid={props.data.tecneoutcome51.childImageSharp.fluid} />
              <Img fluid={props.data.tecneoutcome52.childImageSharp.fluid} />
              <Img fluid={props.data.tecneoutcome53.childImageSharp.fluid} />
              <Img fluid={props.data.tecneoutcome54.childImageSharp.fluid} />
              <Img fluid={props.data.tecneoutcome55.childImageSharp.fluid} />
          </Slider>

          <div className="process">
          <div className="process-left">
          <p className="half-textlarge">
          Our hypothesis was proven right!
          <br />
          <br />
          Interaction with a voice bot gave rich data useful to generate actionable insights.
          </p>
          </div>
          <div className="process-left">
          <p>ACs found voice to be an effecient and easy way to captuer details level information about on-grount activities.</p>
          <p>Prompts and follow-up questions generated by bot helped ACs think and recall more information.</p>
          <p>GPT summarised details, identify and tag the appropriate interaction purpose, 
            and generate insights at a single visit level, as well as look at patterns if the same 
            MC was visited multiple times.</p>
          <p>The voice bot was able to recogonise regional Indonesian dialects and accents.</p>
          </div>
          </div>
            


          <div className="changebg">

          <div className="process">
            <div className="process-left">
            <p className="half-textlarge">
            We equipped AC with a new mobile app that had access to all up-to-date information about their MC.
            </p>
            </div>
            <div className="process-right">
            <h4>Digital prototype and card sorting exercise</h4>  
            <p>
            We created a figma prototype of the new AC mobile app. This was then put in 
            front of the ACs to consolidate the transactional and operational
             data points they need about their MCs they manage on a daily basis.
            <br />
            <br />
            We ran a card sorting exercise to design the information architecture of the mobile app.
            </p>
            </div>
          </div>


          <div className="image">
          <Img fluid={props.data.tecneoutcome56.childImageSharp.fluid} />
          </div> 

          <div className="image">
          <Img fluid={props.data.tecneoutcome57.childImageSharp.fluid} />
          </div> 

          <p className="full-textlarge">
          The mobile app was a hit! <br />
          ACs were able to access the various data points about thier MCs quickly and easily.
          </p>
          </div>

          <div className="process">
            <div className="process-left">
            <p className="half-textlarge">
            We ran 2 pilots that involved adding new roles to support ACs by taking things off their plate.
            </p>
            </div>
            <div className="process-right">
            <h4>
          Pilot 1 - A new On-field trainer role to support ACs with training efforts
          </h4>
          <p>
          ACs can call upon on-field trainers when they feel that their MCs are being repeatedly dependent on them to use Jiva's tools (app), 
          and are struggling to follow Jiva's rules & processes.
          <br />
          <br />
          The on-field trainer is more capable at educating the MCs on how to use Jiva's tools 
          and processes so that they can work with Jiva in a self-serve manner.
          </p>
            </div>
          </div>

          

          <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneoutcome58.childImageSharp.fluid} />
              <Img fluid={props.data.tecneoutcome581.childImageSharp.fluid} />
          </Slider> 

          <div className="process">
          <div className="process-left">
          <h4>
          Pilot 2 - A remote team to serve low-value MCs
          </h4>
          <p>
          ACs won't have to serve MCs which are of low value to Jiva. They can focus their effort on mid & high-value MCs.
          <br />
          <br />
          The remote team will provide end-to-end support to low-value MCs through messages and phone calls.
          </p>
          </div>
          </div>

          <div className="image">
          <Img fluid={props.data.tecneoutcome59.childImageSharp.fluid} />
          </div> 

          <p className="full-textlarge">
          We reduced MC's dependency on their ACs for non-operational related activies, which resulted in  
         ACs experising higher bandwidth to focus on more more higher value MCs and their operational needs.
          </p>



          <div className="changebg">
          

          <div className="process">
            <div className="process-left">
            <p className="half-textlarge">
            We also prototyped and tested other initiatives that helped the ACs to be more capable and effectively serving their MCs.
            </p>
            </div>
            <div className="process-right">
            <h4>
          Some of the initiatives include:
          </h4>
          <p>
          
          Farmer document collection event — creating a social way to complete an ardous KYC process, a requirement for Jiva.
          <br />
          <br />
          Low friction transactional channel — lowering the technology barrier for new MCs to transact with Jiva.
          <br />
          <br />
          AC upskilling courses — equipping ACs with specfic capabilities in order to be effective at serving MCs.
          </p>
            </div>
          </div>


          <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneoutcome60.childImageSharp.fluid} />
              <Img fluid={props.data.tecneoutcome61.childImageSharp.fluid} />
              <Img fluid={props.data.tecneoutcome62.childImageSharp.fluid} />
          </Slider>
          </div>
          

           </section>    */}


        </div>
      </div>

      <section className="fullspan">
              <div className="image">
                <Img fluid={props.data.tecneoutcome69.childImageSharp.fluid} />
              </div>
              <div className="image">
                <Img fluid={props.data.tecneoutcome70.childImageSharp.fluid} />
              </div>
      </section>



      <div className="project-wrapper">
          <div className="project-content">
          {/* <div className="changebg">

          
          <div className="process">           
            <div className="process-left">
              <h4>
              Problems faced during the project 
              and how they were solved
              </h4>
              </div>
              <div className="process-right">
              <p>
              The challenge was to identify ways in which our product 
              assists the coaches to do their job better and not to 
              replace their role. 
              </p> 
              <p>
              After collaborating with the coaches we found out our
               USP which was to equip them with factual data which 
               they could not know during their training sessions; 
               such as data about impact strength and area of impact 
               of each hit. 
              </p>               
              </div>
            </div>
            

            <div className="process">           
              <div className="process-right">
              <p>
              Another challenge was to ensure that our product did not
               interfere or distract the current training routines and sessions.
              </p> 
              <p>
              The insights from our research allowed us to design various
               micro & macro interactions that made sure our product 
               complimented the workflow of coaches and trainers. 
               For e.g. Highlight-cards gave quick data insights.
              </p>               
              </div>
            </div>

            <div className="process">           
              <div className="process-right">
              <p>
              Coaches during training need to see live feed of
               data of their trainers and also see data of the 
               elapsed time. The use of bluetooth (to connect 
               and exchange data/information between the gloves
                and mobile app) meant putting considerable strain 
                on the database, resulting in slow loading time.
              </p> 
              <p>
              Our solution was to take snapshots (pictures) of 
              the data during training and store that data locally.
               So  if the user was to scroll back in the timeline, 
               either during or at the end of the training session,
                instead of calling the data from the server we simply
                 showed the snapshots thus eliminating any loading time. 
              </p>               
              </div>
            </div>

            <br />
            <br />
            <div className="process">           
            <div className="process-left">
                  <h4>
                  Reflection - what I learnt during the project & what I could have done better.
                  </h4>
            </div>
              <div className="process-right">
              <p>
              Getting people to talk about an important, but highly complex topic was difficult.    
              </p> 
              <p>
              We approached people in different ways (short survey, contextual interview, 
              phone conversation, in-person chats) depending on their comfort. Our 
              strategy involved raising awareness around the topic, and being open 
              to listening to stories about people's personal experiences. 
              </p>                
              </div>
            </div>
          </div> */}

          <br />
          <br />
          <br />
          
          
          <p className="full-text">
          Thank you & keep optimising your internal team's workflows <span role="img" aria-labelledby="tecneemoji">🙌🛠️</span> 
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          
          <div className="full-text">
          <div className="hp-featured__hr-line" />
          <h5>NEXT PROJECT</h5>
          </div>
          <br />


        </div>
      </div>

      {/* <section className="fullspan">
              <div className="image">
              <Img fluid={props.data.tecneprocess12.childImageSharp.fluid} />
            </div> 
      </section> */}


      <section id="two" className="tiles inner">
      {/* <div className="hp-featured__hr-line" />
        <h4>NEXT PROJECT</h4> */}
            <div className="tiles__wrapper">
              <a href="/work/service-design-progression">
                <div className="tiles__content">
                  <div className="tiles__info">
                    {/* <div className="tiles__number">01</div> */}

                    {/* <h2 className="tiles__title">
                      electric car charging service.
                    </h2> */}

                    <div className="tiles__text">
                    Improving the credit recovery timelines, 
                    motivation and loyalty of users by designing 
                    a multi-component Progression System that 
                    engages users in a variety of ways.
                    </div>
                    <br />
                    <p className="maisontags-card">Research, Service Design, User Experience, Digital UX/UI, UX Strategy</p>
                    {/* <div className="tiles__tags">
                      <ul>
                        <li>SERVICE DESIGN</li>
                        <li>USER EXPERIENCE</li>
                        <li>DIGITAL UX/UI</li>
                        <li>INTERACTION DESIGN</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="tiles__image">
                  <img src={pic04} alt="" />
                </div>
              </a>
            </div>
      </section>
  </div>
  </Layout>
)
}


export default ServiceDesignTecne

export const fluidTecneImage = graphql`
  fragment fluidTecneImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    tecnecover: file(
      relativePath: { eq: "projects/service/acrole/cover/acrole-cover.png" }
    ) {
      ...fluidTecneImage
    }
    tecne_logo: file(
      relativePath: { eq: "projects/service/tecne/tecne-logo.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecnechallenge1: file(
      relativePath: { eq: "projects/service/acrole/challenge/1.png" }
    ) {
      ...fluidTecneImage
    }
    tecnechallenge2: file(
      relativePath: { eq: "projects/service/acrole/challenge/2.png" }
    ) {
      ...fluidTecneImage
    }
    tecnechallenge3: file(
      relativePath: { eq: "projects/service/acrole/challenge/3.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess3: file(
      relativePath: { eq: "projects/service/acrole/process/3.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess4: file(
      relativePath: { eq: "projects/service/acrole/process/4.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess5: file(
      relativePath: { eq: "projects/service/acrole/process/5.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess6: file(
      relativePath: { eq: "projects/service/acrole/process/6.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess7: file(
      relativePath: { eq: "projects/service/acrole/process/7.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess8: file(
      relativePath: { eq: "projects/service/acrole/process/8.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess9: file(
      relativePath: { eq: "projects/service/acrole/process/9.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess10: file(
      relativePath: { eq: "projects/service/acrole/process/10.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess11: file(
      relativePath: { eq: "projects/service/acrole/process/11.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess12: file(
      relativePath: { eq: "projects/service/acrole/process/12.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess13: file(
      relativePath: { eq: "projects/service/acrole/process/13.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess14: file(
      relativePath: { eq: "projects/service/acrole/process/14.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess15: file(
      relativePath: { eq: "projects/service/acrole/process/15.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess16: file(
      relativePath: { eq: "projects/service/acrole/process/16.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess17: file(
      relativePath: { eq: "projects/service/acrole/process/17.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess18: file(
      relativePath: { eq: "projects/service/acrole/process/18.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess19: file(
      relativePath: { eq: "projects/service/acrole/process/19.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess20: file(
      relativePath: { eq: "projects/service/acrole/process/20.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess21: file(
      relativePath: { eq: "projects/service/acrole/process/21.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess22: file(
      relativePath: { eq: "projects/service/acrole/process/22.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess23: file(
      relativePath: { eq: "projects/service/acrole/process/23.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess24: file(
      relativePath: { eq: "projects/service/acrole/process/24.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess25: file(
      relativePath: { eq: "projects/service/acrole/process/25.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess26: file(
      relativePath: { eq: "projects/service/acrole/process/26.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess27: file(
      relativePath: { eq: "projects/service/acrole/process/27.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess28: file(
      relativePath: { eq: "projects/service/acrole/process/28.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess29: file(
      relativePath: { eq: "projects/service/acrole/process/29.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess30: file(
      relativePath: { eq: "projects/service/acrole/process/30.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess31: file(
      relativePath: { eq: "projects/service/acrole/process/31.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess32: file(
      relativePath: { eq: "projects/service/acrole/process/32.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess33: file(
      relativePath: { eq: "projects/service/acrole/process/33.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess34: file(
      relativePath: { eq: "projects/service/acrole/process/34.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess35: file(
      relativePath: { eq: "projects/service/acrole/process/35.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess36: file(
      relativePath: { eq: "projects/service/acrole/process/36.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess37: file(
      relativePath: { eq: "projects/service/acrole/process/37.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess38: file(
      relativePath: { eq: "projects/service/acrole/process/38.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess39: file(
      relativePath: { eq: "projects/service/acrole/process/39.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess40: file(
      relativePath: { eq: "projects/service/acrole/process/40.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess41: file(
      relativePath: { eq: "projects/service/acrole/process/41.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess42: file(
      relativePath: { eq: "projects/service/acrole/process/42.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess43: file(
      relativePath: { eq: "projects/service/acrole/process/43.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess44: file(
      relativePath: { eq: "projects/service/tecne/process/44.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome45: file(
      relativePath: { eq: "projects/service/acrole/outcome/45.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome46: file(
      relativePath: { eq: "projects/service/acrole/outcome/46.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome47: file(
      relativePath: { eq: "projects/service/acrole/outcome/47.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome48: file(
      relativePath: { eq: "projects/service/acrole/outcome/48.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome49: file(
      relativePath: { eq: "projects/service/acrole/outcome/49.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome50: file(
      relativePath: { eq: "projects/service/acrole/outcome/50.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome51: file(
      relativePath: { eq: "projects/service/acrole/outcome/51.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome52: file(
      relativePath: { eq: "projects/service/acrole/outcome/52.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome53: file(
      relativePath: { eq: "projects/service/acrole/outcome/53.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome54: file(
      relativePath: { eq: "projects/service/acrole/outcome/54.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome55: file(
      relativePath: { eq: "projects/service/acrole/outcome/55.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome56: file(
      relativePath: { eq: "projects/service/acrole/outcome/56.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome57: file(
      relativePath: { eq: "projects/service/acrole/outcome/57.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome58: file(
      relativePath: { eq: "projects/service/acrole/outcome/58.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome581: file(
      relativePath: { eq: "projects/service/acrole/outcome/581.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome59: file(
      relativePath: { eq: "projects/service/acrole/outcome/59.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome60: file(
      relativePath: { eq: "projects/service/acrole/outcome/60.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome61: file(
      relativePath: { eq: "projects/service/acrole/outcome/61.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome62: file(
      relativePath: { eq: "projects/service/acrole/outcome/62.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome64: file(
      relativePath: { eq: "projects/service/acrole/outcome/64.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome66: file(
      relativePath: { eq: "projects/service/acrole/outcome/66.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome67: file(
      relativePath: { eq: "projects/service/acrole/outcome/67.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome68: file(
      relativePath: { eq: "projects/service/acrole/outcome/68.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome69: file(
      relativePath: { eq: "projects/service/acrole/outcome/69.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome70: file(
      relativePath: { eq: "projects/service/acrole/outcome/70.jpg" }
    ) {
      ...fluidTecneImage
    }
    things_logo: file(
      relativePath: { eq: "articles/mobileiot/things-logo.png" }
    ) {
      ...fluidTecneImage
    }
    tecne_process: file(
      relativePath: { eq: "projects/process/tecne-process.png" }
    ) {
      ...fluidTecneImage
    }
    project1: file(relativePath: { eq: "projects/3.png" }) {
      ...fluidTecneImage
    }
    project2: file(relativePath: { eq: "projects/10.png" }) {
      ...fluidTecneImage
    }
  }
`
